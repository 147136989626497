import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from 'react-router-dom';
import { DashboardTwoTone, DashboardRounded } from '@mui/icons-material';
import { navItems, logoutItem } from './NavItems';
import { FaBox } from 'react-icons/fa';

const Header = () => {
    const navigate = useNavigate();
    const [anchorElNav, setAnchorElNav] = React.useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleRoute = (url) => {
        navigate('/' + url);
        setAnchorElNav(null);
    };

    return (
        <AppBar position='sticky'>
            <Container maxWidth='100%' sx={{ backgroundColor: '#3c3f44', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                <Toolbar disableGutters>
                    <FaBox size={'23px'} style={{ marginRight: '20px'}} />
                    <Typography
                        variant='h6'
                        noWrap
                        component='a'
                        href='/'
                        sx={{
                            mr: 2,
                            display: { xs: 'none', md: 'flex' },
                            fontFamily: 'calibri',
                            fontWeight: 700,
                            fontSize: 25,
                            letterSpacing: '.15rem',
                            color: 'inherit',
                            textDecoration: 'none'
                        }}>
                        DASHBOARD
                    </Typography>

                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                        <IconButton size='large' aria-label='account of current user' aria-controls='menu-appbar' aria-haspopup='true' onClick={handleOpenNavMenu} color='inherit'>
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id='menu-appbar'
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left'
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left'
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{ display: { xs: 'block', md: 'none' } }}>
                            {navItems.map((item) => (
                                <MenuItem key={item.value} onClick={() => handleRoute(item.route)}>
                                    <Typography textAlign='center'>{item.value}</Typography>
                                </MenuItem>
                            ))}
                            <MenuItem key={logoutItem.value} onClick={() => handleRoute(logoutItem.route)}>
                                <Typography textAlign='center'>{logoutItem.value}</Typography>
                            </MenuItem>
                        </Menu>
                    </Box>

                    <DashboardRounded sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} />
                    <Typography
                        variant='h5'
                        noWrap
                        component='a'
                        href=''
                        sx={{
                            mr: 2,
                            display: { xs: 'flex', md: 'none' },
                            flexGrow: 1,
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none'
                        }}>
                        DASHBOARD
                    </Typography>
                </Toolbar>
            </Container>
        </AppBar>
    );
};

export default Header;
