import React, { useEffect, useState } from 'react';
import { Box, Button, Typography, Paper, Card, CardContent } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import DrawerNav from '../Navigation/DrawerNav';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { BASE_URL } from '../../Api/Constants';

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

function ReorderQuiz() {
    const navigate = useNavigate();
    const [examsList, setExamsList] = useState([]);

    useEffect(() => {
        fetchQuizzes();
    }, []);

    const fetchQuizzes = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/api/v1/quizzes`);
            if (Array.isArray(response.data.data.list)) {
                setExamsList(response.data.data.list);
                toast.success('Quizzes fetched from database', {
                    position: 'bottom-left'
                });
            } else {
                console.error('Expected an array but got:', response.data.data.list);
            }
        } catch (error) {
            console.error('Error fetching quizzes:', error);
            toast.error('Failed to fetch quizzes', {
                position: 'bottom-left'
            });
        }
    };

    const handleOnDragEnd = (result) => {
        if (!result.destination) return;
        const reorderedList = reorder(examsList, result.source.index, result.destination.index);
        setExamsList(reorderedList);
    };

    const handleSaveOrder = async () => {
        try {
            const quizzesWithOrder = examsList.map((quiz, index) => ({
                id: quiz._id,
                order: index + 1
            }));

            console.log('quizzesWithOrder : ', quizzesWithOrder)

            await axios.post(`${BASE_URL}/api/v1/quizzes-reorder`, { quizzes: quizzesWithOrder });
            toast.success('Quiz order saved successfully', {
                position: 'bottom-left'
            });
        } catch (error) {
            console.error('Error saving quiz order:', error);
            toast.error('Failed to save quiz order', {
                position: 'bottom-left'
            });
        }
    };

    return (
        <>
            <Box sx={{ display: 'flex' }}>
                <DrawerNav />
                <Box component='main' sx={{ flexGrow: 1, p: 3 }}>
                    <Typography variant='h4' sx={{ my: 2, fontWeight: 'bolder', color: '#4b4848' }}>
                        Reorder Quizzes
                    </Typography>
                    <Button variant='contained' color='primary' onClick={handleSaveOrder} sx={{ mb: 3 }}>
                        Save Order
                    </Button>
                    <Paper sx={{ p: 2 }}>
                        <DragDropContext onDragEnd={handleOnDragEnd}>
                            <Droppable droppableId="quizzes">
                                {(provided) => (
                                    <Box {...provided.droppableProps} ref={provided.innerRef}>
                                        {examsList.map((quiz, index) => (
                                            <Draggable key={quiz._id} draggableId={quiz._id} index={index}>
                                                {(provided) => (
                                                    <Card ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} sx={{ mb: 2 }}>
                                                        <CardContent>
                                                            <Typography variant="h6" component="div">
                                                                {quiz.name}
                                                            </Typography>
                                                            <Typography variant="body2" color="text.secondary">
                                                                {quiz.description}
                                                            </Typography>
                                                        </CardContent>
                                                    </Card>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </Box>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </Paper>
                </Box>
            </Box>
            <ToastContainer />
        </>
    );
}

export default ReorderQuiz;
