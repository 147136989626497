import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { navItems, logoutItem, navItems2 } from './NavItems';

function DrawerNav() {
    const navigate = useNavigate();
    const isMobile = useMediaQuery('(max-width:600px)');

    const handleRoute = (url) => {
        if (url === 'login') {
            localStorage.clear();
        }
        navigate('/' + url);
    };

    const drawerWidth = 300;

    return (
        <>
            <CssBaseline />
            <Drawer
                variant='permanent'
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
                    display: isMobile ? 'none' : 'block',
                    position: 'relative',
                    zIndex: 1
                }}>
                <Toolbar />
                <Box sx={{ overflow: 'auto' }}>
                    <List>
                        {navItems.map((item) => (
                            <ListItem key={item.value} disablePadding>
                                <ListItemButton onClick={() => handleRoute(item.route)}>
                                    <ListItemIcon>{item.icon}</ListItemIcon>
                                    <ListItemText primary={item.value} />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                    <Divider />
                    <List>
                        {navItems2.map((item) => (
                            <ListItem key={item.value} disablePadding>
                                <ListItemButton onClick={() => handleRoute(item.route)}>
                                    <ListItemIcon>{item.icon}</ListItemIcon>
                                    <ListItemText primary={item.value} />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                    <Divider />
                    <List>
                        <ListItem key={logoutItem.value} disablePadding>
                            <ListItemButton onClick={() => handleRoute(logoutItem.route)}>
                                <ListItemIcon>{logoutItem.icon}</ListItemIcon>
                                <ListItemText primary={logoutItem.value} />
                            </ListItemButton>
                        </ListItem>
                    </List>
                </Box>
            </Drawer>
        </>
    );
}

export default DrawerNav;
