import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { BASE_URL } from '../../Api/Constants';
import { Box, Button, Paper, Typography, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MainLayout from '../Navigation/MainLayout';

function ReorderCourseTopics() {
    const navigate = useNavigate();
    const { id } = useParams(); // Get courseId from URL parameters
    const [topicsList, setTopicsList] = useState([]);

    useEffect(() => {
        fetchCourseTopics();
    }, []);

    const fetchCourseTopics = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/api/v1/course-topic?courseId=${id}`);
            if (Array.isArray(response.data.data.list)) {
                setTopicsList(response.data.data.list);
                toast.success('Course topics fetched from database', {
                    position: 'bottom-left'
                });
            } else {
                console.error('Expected an array but got:', response.data.data.list);
            }
        } catch (error) {
            console.error('Error fetching course topics:', error);
            toast.error('Failed to fetch course topics', {
                position: 'bottom-left'
            });
        }
    };

    const handleDragEnd = (result) => {
        if (!result.destination) return;
        const reorderedList = Array.from(topicsList);
        const [removed] = reorderedList.splice(result.source.index, 1);
        reorderedList.splice(result.destination.index, 0, removed);

        setTopicsList(reorderedList);
    };

    const handleSaveOrder = async () => {
        try {
            const reorderedTopics = topicsList.map((topic, index) => ({
                ...topic,
                order: index + 1,
            }));

            console.log('reorderedTopics: ', reorderedTopics)

            await axios.put(`${BASE_URL}/api/v1/reorder-course-topic/${id}`, {
                topics: reorderedTopics,
            });

            toast.success('Course topics reordered successfully', {
                position: 'bottom-left',
            });
            navigate(-1);
        } catch (error) {
            console.error('Error saving reordered topics:', error);
            toast.error('Failed to save reordered topics', {
                position: 'bottom-left',
            });
        }
    };

    return (
        <>
            <MainLayout>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', p: 3 }}>
                    <Typography variant='h4' sx={{ mb: 2, fontWeight: 'bolder', color: '#4b4848' }}>
                        Reorder Course Topics
                    </Typography>

                    <DragDropContext onDragEnd={handleDragEnd}>
                        <Droppable droppableId="topics">
                            {(provided) => (
                                <TableContainer
                                    component={Paper}
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    sx={{ width: '80%', boxShadow: 3 }}
                                >
                                    <Table sx={{ minWidth: 650 }}>
                                        <TableBody>
                                            {topicsList.map((topic, index) => (
                                                <Draggable
                                                    key={topic._id}
                                                    draggableId={topic._id}
                                                    index={index}
                                                >
                                                    {(provided) => (
                                                        <TableRow
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            sx={{ '&:hover': { backgroundColor: '#f5f5f5' } }}
                                                        >
                                                            <TableCell>{index + 1}</TableCell>
                                                            <TableCell>{topic.name}</TableCell>
                                                        </TableRow>
                                                    )}
                                                </Draggable>
                                            ))}
                                            {provided.placeholder}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            )}
                        </Droppable>
                    </DragDropContext>

                    <Button
                        variant='contained'
                        color='primary'
                        sx={{ mt: 3 }}
                        onClick={handleSaveOrder}
                    >
                        Save Order
                    </Button>
                </Box>
                <ToastContainer />
            </MainLayout>

        </>
    );
}

export default ReorderCourseTopics;
