import * as React from 'react'
import secureLocalStorage from 'react-secure-storage'
import { useEffect } from 'react'
import { Grid, Snackbar, Stack, Table, TableBody } from '@mui/material'
import { Box } from '@mui/system'
import MuiAlert from '@mui/material/Alert'
import { useLocation, useNavigate } from 'react-router-dom'
import { BASE_URL } from '../../Api/Constants'
import DrawerNav from '../Navigation/DrawerNav'
import { styled } from '@mui/material/styles'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { Edit } from '@mui/icons-material'
import CustomThreeDots from '../Loader/CustomThreeDots'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#262726',
        color: theme.palette.common.white,
        fontSize: 20
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 16
    }
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0
    }
}))

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />
})

const Users = () => {
    const [apiCalled, setApiCalled] = React.useState(false)
    const location = useLocation()
    const [inputUid, setInputUid] = React.useState('')
    const [showLoader, setShowLoader] = React.useState(true)

    let navigate = useNavigate()
    const token = secureLocalStorage.getItem('token')
    const dashboardUid = secureLocalStorage.getItem('uid')

    const [usersList, setUsersList] = React.useState([])
    const [awarded, setAwarded] = React.useState(true)

    // Success
    const [openSuccessAlert, setOpenSuccessAlert] = React.useState(false)
    const closeSuccessAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setOpenSuccessAlert(false)
    }

    // Error
    const [openErrorAlert, setOpenErrorAlert] = React.useState(false)
    const closeErrorAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setOpenErrorAlert(false)
    }

    const resetAllFields = () => {
        setApiCalled(false)
        setInputUid('')
    }

    const getLeaderboardOfContest = async (contestId) => {
        try {
            const requestOptions = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' }
            }

            await fetch(`${BASE_URL}/api/leaderboard`, requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    console.log(data)
                    if (data.status === 200) {
                        setApiCalled(true)
                        setUsersList(data.data.list)
                        setAwarded(data.data.awarded)
                    } else {
                        setOpenErrorAlert(true)
                    }
                })
                .catch((error) => {
                    console.log('Error : ', error)
                    setOpenErrorAlert(true)
                    setUsersList([
                        {
                            uid: 1,
                            username: 'Kiranpal123',
                            coins: 1
                        }
                    ])
                })
        } catch {
        } finally {
            setShowLoader(false)
        }
    }

    useEffect(() => {
        const path = location.pathname
        const contestId = path.substring(22)
        console.log('Contest Id : ', contestId)
        getLeaderboardOfContest(contestId)
        window.scrollTo(0, 0)
    }, [])

    const handleEditQuestion = (id) => {
        navigate('/edit-user?id=' + id)
    }

    return (
        <Box sx={{ display: 'flex' }}>
            <DrawerNav />
            {showLoader && <CustomThreeDots />}
            <Box component='main' sx={{ flexGrow: 1, p: 3 }}>
                {usersList.length > 0 && (
                    <>
                        <Box sx={{ flexGrow: 2 }}>
                            <Grid align='center' justify='center' spacing={2}>
                                <Grid item lg={4} md={6} sm={12} className=''>
                                    <h1 className='text-4xl font-bold my-5 text-gray-700'> Users </h1>
                                </Grid>
                                <Box sx={{ flexGrow: 2 }}>
                                    <Grid align='center' justify='center' sx={{ margin: 1 }}>
                                        <>
                                            <Table sx={{ width: '90%', marginTop: 4, marginBottom: 30 }} aria-label='customized table'>
                                                <TableHead>
                                                    <TableRow>
                                                        <StyledTableCell className='rounded-tl-lg' align='left'>
                                                            S. No
                                                        </StyledTableCell>
                                                        <StyledTableCell align='left'>Username</StyledTableCell>
                                                        <StyledTableCell align='left'>Points</StyledTableCell>
                                                        <StyledTableCell className='rounded-tr-lg' align='left'>
                                                            Edit
                                                        </StyledTableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {usersList.length > 0 &&
                                                        usersList.map((data, index) => {
                                                            return (
                                                                <StyledTableRow key={data._id} sx={{ border: '1px solid gray' }}>
                                                                    <StyledTableCell align='left'>{index + 1}</StyledTableCell>
                                                                    <StyledTableCell align='left'>{data.username}</StyledTableCell>
                                                                    <StyledTableCell align='left'>{data.coins}</StyledTableCell>
                                                                    <StyledTableCell align='left' onClick={() => handleEditQuestion(data.uid)}>
                                                                        <Edit sx={{ cursor: 'pointer' }} />
                                                                    </StyledTableCell>
                                                                </StyledTableRow>
                                                            )
                                                        })}
                                                </TableBody>
                                            </Table>
                                        </>
                                    </Grid>
                                </Box>
                            </Grid>

                            <Stack spacing={2} sx={{ width: '100%' }}>
                                <Snackbar open={openSuccessAlert} autoHideDuration={2000} onClose={closeSuccessAlert}>
                                    <Alert onClose={closeSuccessAlert} severity='success' sx={{ width: '100%' }}>
                                        Success!!!
                                    </Alert>
                                </Snackbar>
                            </Stack>

                            <Stack spacing={2} sx={{ width: '100%' }}>
                                <Snackbar open={openErrorAlert} autoHideDuration={2000} onClose={closeErrorAlert}>
                                    <Alert onClose={closeErrorAlert} severity='error' sx={{ width: '100%' }}>
                                        Error!!!
                                    </Alert>
                                </Snackbar>
                            </Stack>
                        </Box>
                    </>
                )}
            </Box>
        </Box>
    )
}

export default Users
